<template>
  <!--begin::Profile 4-->
  <div class="d-flex flex-row">
    <!--begin::Aside-->
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    ></div>
    <!--end::Aside-->
  </div>
  <!--end::Profile 4-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Profile-4.vue",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile 2" }]);
  }
};
</script>
